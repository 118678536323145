.virtual {
  margin: 6rem 0 6rem 0;
  display: flex;
  padding: 2rem;
  justify-content: space-around;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.left > :nth-child(1) {
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
  width: 20rem;
}

.left > :nth-child(2) {
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left > :nth-child(3) {
    font-size: 1.6rem;
    font-weight: bold;
}

.left > :last-child {
    width: 10rem;
    position: absolute;
    bottom: 3rem;
}

.wrapper {
    width: 30rem;
    overflow: hidden;
}

@media screen and (max-width: 860px) {
 .virtual {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 2rem 0 5rem 0;
  gap: 1rem;
  text-align: center;
 }
}
