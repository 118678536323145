.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
}

.wrapper {
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}

.wrapper > img {
    width: 25rem;
    justify-self: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container > :nth-child(1) {
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
}

.container > :nth-child(2) {
    font-size: .8rem;
    text-transform: uppercase;
    display: block;
}

.wrapper > :nth-child(3) {
    text-align: right;
}

.reviews {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase
}

.carousal {
    width: 100%;
}

.tcarsoual {
    padding: 2rem;
}

.testimonial {
    display: flex;flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: white;
    border-radius: .5rem;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
}

.testimonial > :nth-child(1) {
    border-radius: 50%;
    border: 4px solid var(--black);
    width: 3rem;
    position: absolute;
    top: -1.5rem;
}

.testimonial > :nth-child(2) {
    font-size: .8rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 3rem;
}

.testimonial > :nth-child(4) {
    font-weight: bold;
}
.testimonial > :nth-child(3) {
   width: 90%;
   background: rgb(198,198,198);
   height: 1px;
   border: none;
}

@media screen and (max-width: 860px) {
    .testimonials {
        margin: 3rem 0 0 0;
    }
}


@media screen and (max-width: 640px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
    .testimonials {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wrapper > img {
        width: 80vw;
    }

    .wrapper > :nth-child(1), .wrapper > :nth-child(3) {
        text-align: center;
    }
}

