.s-container {
    position: relative;
}

.s-container .swiper {
    position: unset;
    width: 70%;
    height: 12rem;
}

.s-container .swiper-slide {
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev {
    left: 6rem;
}
.s-container .swiper-button-next {
    right: 6rem;
}

.s-container .swiper-button-next, .s-container .swiper-button-prev {
    color: var(--black);
}

.left-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
}

.name > :nth-child(1) {
    font-size: 1.4rem;
    font-weight: 600;
}
.name > :nth-child(2) {
    font-size: .8rem;
}

.left-s > :nth-child(2) {
    font-size: 2rem;
    font-weight: bold;
}

.left-s > :nth-child(3) {
    font-size: .6rem;
    border: 1px solid var(--black);
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

.img-p {
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    bottom: -20%;
    width: 40%;
    height: 100%;
}

@media screen and (max-width: 860px) {
    .s-container .swiper-button-prev {
        display: none;
    }
    .s-container .swiper-button-next {
display: none;
    }
    .img-p {
        bottom: -20%;
        width: 30%;
        height: 80%;
    }

    .s-container .swiper {
        position: unset;
        width: 80%;
        height: 12rem;
    }
    .name > :nth-child(1) {
        font-size: 1.2rem;
    }
    .name > :nth-child(2) {
        font-size: .7rem;
    }
    
    .left-s > :nth-child(2) {
        font-size: 1.8rem;
    }
    
}